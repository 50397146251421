import React from 'react';
import Image from 'react-bootstrap/Image';
import FileForm from "./FileForm";
import Cookies from 'universal-cookie';
import { withRouter } from "react-router-dom"
import { useSpring, animated } from 'react-spring'
import './App.css';
import Footer from "./Footer";
import product from './img/product.png'
import logo from './img/logo.png'
import product4 from './img/product4.png'
import NameForm from "./NameForm";
import tagline from "./img/tagline.png";
import topline from "./img/topline.png";

const calc = (x, y) => [-(y - window.innerHeight / 2) / 200, (x - window.innerWidth / 2) / 200, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const cookies = new Cookies();
/*
function ProductHolder() {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 1, tension: 500, friction: 100 } }))
    return (
        <animated.div
            class="Product-Holder"
            onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
            onMouseLeave={() => set({ xys: [0, 0, 1] })}
            style={{ transform: props.xys.interpolate(trans) }}>
            <Image src={product} alt="Blood Orange Seltzer Can" className="img-fluid Product-Image no-gutters"/>
        </animated.div>
    )
}
*/
class Sucess extends React.Component {
    constructor(props) {
        super(props);
        if (cookies.get('myAccess') !== 'True') {
            this.props.history.push("/");
        }
    }
    render() {
        return (
            <div className="App">
                <div className="App2">
                    <div className="container-fluid Main-Container">
                        <div className="row h-100 w-100 justify-content-end">
                            <div className="Main-Right d-none d-md-flex col-3">
                                <Image src={product} alt="BUD LIGHT SELTZER HARD SODA" className=" img-fluid Product-Image no-gutters"/>
                            </div>
                            <div className="Main-Center d-flex col-12 col-md-6">
                                <div className="container-fluid">
                                    <div className="row Top-Mobile align-items-end justify-content-center">
                                        <div className="d-flex col-12 justify-content-center">
                                        </div>
                                    </div>
                                    <div className="container-fluid transmiddle">
                                        <div className="contentright">
                                            <div className="bud33 ultrayellow pt-1 pb-4">UPLOAD YOUR PIC <br /> FOR A CHANCE TO</div>
                                            <div className="bud71 ultrablue pb-5">WIN $500</div>
                                            <div><Image src={product4} alt="BUD LIGHT SELTZER HARD SODA" className="img-fluid Product3-Image mb-5" /></div>
                                            <div>
                                                <FileForm />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Main-Left d-none d-md-flex col-3">
                                <Image src={topline} alt="BUD LIGHT SELTZER HARD SODA" className="img-fluid Top-Image no-gutters"/>
                                <Image src={tagline} alt="BUD LIGHT SELTZER HARD SODA" className="img-fluid Tag-Image no-gutters"/>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}
export default withRouter(Sucess);
